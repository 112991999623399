import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import PageTransitionContainer from "../../components/PageTransitionContainer"
import "../../styles/pages/calculators.css"

const Index = (props) => {
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="home">
        <Seo
          title="Terms And Conditions | RateLock"
          location={props.location}
        />
        <section id="terms-and-conditions">
          <h1 className="page-heading">
            Terms And Conditions of www.ratelock.ca
          </h1>
          <div>
            <p>
              Below are the Terms and Conditions for use of www.ratelock.ca.
              Please read these carefully. If you need to contact us regarding
              any aspect of the following terms of use of our website, please
              contact us on the following email address –{" "}
              <a
                href="mailto:hello@ratelock.ca"
                style={{
                  fontSize: "1em",
                  fontWeight: "bold",
                  textDecoration: "underline"
                }}
              >
                hello@ratelock.ca
              </a>
            </p>
            <p>
              By accessing the content of www.ratelock.ca (hereafter referred to
              as website) you agree to the terms and conditions set out herein
              and also accept our Privacy Policy. If you do not agree to any of
              the terms and conditions you should not continue to use the
              Website and leave immediately.
            </p>
            <p>
              You agree that you shall not use the website for any illegal
              purposes, and that you will respect all applicable laws and
              regulations.
            </p>
            <p>
              You agree not to use the website in a way that may impair the
              performance, corrupt or manipulate the content or information
              available on the website or reduce the overall functionality of
              the website.
            </p>
            <p>
              You agree not to compromise the security of the website or attempt
              to gain access to secured areas of the website or attempt to
              access any sensitive information you may believe exist on the
              website or server where it is hosted.
            </p>
            <p>
              You agree to be fully responsible for any claim, expense, losses,
              liability, costs including legal fees incurred by us arising from
              any infringement of the terms and conditions in this agreement and
              to which you will have agreed if you continue to use the website.{" "}
            </p>
            <p>
              The reproduction, distribution in any method whether online or
              offline is strictly prohibited. The work on the website and the
              images, logos, text and other such information is the property of
              www.ratelock.ca (unless otherwise stated).{" "}
            </p>
            <p>
              <strong>Disclaimer</strong>
            </p>
            <p>
              Though we strive to be completely accurate in the information that
              is presented on our site and attempt to keep it as up to date as
              possible, in some cases, some of the information you find on the
              website may be slightly outdated.
            </p>
            <p>
              www.ratelock.ca reserves the right to make any modifications or
              corrections to the information you find on the website at any time
              without notice.
            </p>
            <p>
              <strong>Change to the Terms and Conditions of Use</strong>
            </p>
            <p>
              We reserve the right to make changes and to revise the
              above-mentioned Terms and Conditions of use.
            </p>
            <p>
              <i>Last Revised: 11-08-2020</i>
            </p>
          </div>
        </section>
      </Layout>
    </PageTransitionContainer>
  )
}

export default Index
